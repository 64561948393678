* {
  font-family: 'Montserrat', sans-serif;
  /* background-color: #c6a5bb; */
  margin: 0;
}

.app-container {
  margin: 0 10vw;
}


/* NAVBAR COMPONENT */
.navigation {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
}

.navigation p {
  font-size: 16px;
  padding: 5px 0;
}


/* HOME COMPONENT */
.title-container {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* img {
  height: 250px;
  width: 500px;
  position: relative;
  bottom: -30px;
  z-index: 0;
} */

/* .circle-filled {
  height: 10px;
  width: 10px;
  border: 4px solid black;
  border-radius: 50%;
  background-color: black;
} */

.name-header {
  font-family: 'Megrim', cursive;
  font-size: 60px;
}


p {
  font-size: 22px;
  padding: 0 4px;
}


/* CONTACT COMPONENT */
.form-container {
  height: 90vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.form-container > h2 {
  padding: 0 20px;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 60%;
  height: 50vh;
  margin: 0 auto;
}